import GraphTabs from 'graph-tabs';
const tabs = new GraphTabs('tabs')
const tabLegends = new GraphTabs('legends')
const tabAquarium = new GraphTabs('aquarium')
const tabCanyon = new GraphTabs('canyon')
const tabDelfins = new GraphTabs('delfin')
const tabGreenCanyon = new GraphTabs('green-canyon')
const tabSafari = new GraphTabs('safari')
const tabRafting = new GraphTabs('rafting')
const tabOrmana = new GraphTabs('ormana')
const tabBuggy = new GraphTabs('buggy')
const tabBoat = new GraphTabs('boat')
const tabBarborosa = new GraphTabs('barborosa')
const tabTazyConyon = new GraphTabs('tazy')
const tabTransfer = new GraphTabs('transfer')
const tab1 = new GraphTabs('popup-1')
const tab2 = new GraphTabs('popup-2')
const tab3 = new GraphTabs('popup-3')
const tab4 = new GraphTabs('popup-4')
const tab5 = new GraphTabs('popup-5')
const tab6 = new GraphTabs('popup-6')
const tab7 = new GraphTabs('popup-7')
const tab8 = new GraphTabs('popup-8')
const tab9 = new GraphTabs('popup-9')
const tab10 = new GraphTabs('popup-10')




